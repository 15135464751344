import * as React from "react";
import { styled } from "styled-components";
import emailjs from "@emailjs/browser";
import { useAlert } from "react-alert";

const Contact = () => {
  const [submitCount, setSubmitCount] = React.useState(0);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [messageError, setMessageError] = React.useState("");
  const alert = useAlert();

  const onSubmit = () => {
    if (!name || name === "") {
      setNameError("Naam is verplicht.");
    }
    if (!email || email === "") {
      setEmailError("Email is verplicht.");
    }
    if (!message || message === "") {
      setMessageError("Bericht is verplicht.");
    }

    if (
      !(
        !name ||
        name === "" ||
        !email ||
        email === "" ||
        !message ||
        message === ""
      )
    ) {
      const serviceId = "service_3ntxanf";
      const templateId = "template_a9eduvp";

      emailjs
        .send(serviceId, templateId, {
          name,
          email,
          message,
        })
        .then(() => {
          setSubmitCount(submitCount + 1);
          alert.success("Uw bericht is verzonden!");
          setName("");
          setEmail("");
          setMessage("");
        })
        .catch(() => {
          alert.error("Oeps! Er is iets misgelopen, probeer later opnieuw.");
        });
    }
  };

  return (
    <Wrapper>
      <Container>
        <MailContainer>
          <Title>contact</Title>
          <Description>
            We zijn hier om te helpen. Vul de velden in en laat ons weten hoe we
            je van dienst kunnen zijn. We streven ernaar om zo snel mogelijk te
            reageren!
          </Description>
          <MailWrapper>
            <Mail src="assets/images/mail.png" />
            <MailText>friedvanopstal@terragusta.be</MailText>
          </MailWrapper>
        </MailContainer>
        <ContactContainer>
          <ContactInput
            placeholder="Naam"
            value={name}
            onChange={(e) => {
              if (e.target.value.length > 0) {
                setNameError("");
              }
              setName(e.target.value);
            }}
            haserror={nameError !== ""}
          />
          <ContactInput
            placeholder="Email"
            value={email}
            onChange={(e) => {
              if (e.target.value.length > 0) {
                setEmailError("");
              }
              setEmail(e.target.value);
            }}
            haserror={emailError !== ""}
          />
          <ContactTextArea
            placeholder="Bericht"
            value={message}
            onChange={(e) => {
              if (e.target.value.length > 0) {
                setMessageError("");
              }
              setMessage(e.target.value);
            }}
            haserror={messageError !== ""}
          />
          {submitCount > 0 ? (
            <SubmitWrapper>
              <SubmitText>Bedankt voor uw bericht!</SubmitText>
              <SubmitButton onClick={onSubmit}>Verstuur</SubmitButton>
            </SubmitWrapper>
          ) : (
            <SubmitButton onClick={onSubmit}>Verstuur</SubmitButton>
          )}
        </ContactContainer>
      </Container>
    </Wrapper>
  );
};

export default Contact;

const Wrapper = styled.div`
  width: 100%;
  left: 0;
  margin-left: 0;
`;

const Container = styled.div`
  width: 100%;
  background-color: #d3cc9e;

  display: flex;
  flex-direction: row;
  justify-content: center;

  padding-top: 112px;

  @media only screen and (max-width: 935px) {
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 50px;
  }
`;

const MailContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-right: 200px;

  @media only screen and (max-width: 935px) {
    width: 220px;
    margin-right: 0px;
  }

  @media only screen and (max-width: 768px) {
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const Title = styled.p`
  font-family: Operetta;
  font-size: 56px;
  line-height: 56px;

  color: #205b41;

  text-align: left;
  width: 390px;

  margin-top: -10px;

  @media only screen and (max-width: 935px) {
    width: 300px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 35px;
    width: auto;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const Description = styled.p`
  font-family: Permian;
  font-size: 20px;

  color: #205b41;

  text-align: left;
  width: 390px;

  @media only screen and (max-width: 935px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 17px;
    width: auto;
    text-align: center;
  }
`;

const MailWrapper = styled.div`
  display: flex;
  padding-top: 25px;

  text-align: left;
  width: 390px;

  @media only screen and (max-width: 768px) {
    text-align: center;
    width: 100%;
    justify-content: center;
    padding-top: 0px;
    padding-bottom: 25px;
  }
`;

const Mail = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 15px;
`;

const MailText = styled.p`
  font-family: Permian;
  font-size: 16px;

  color: #205b41;
`;

const ContactContainer = styled.div`
  width: 314px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  padding-bottom: 50px;
`;

const ContactInput = styled.input<{ haserror?: boolean }>`
  width: 100%;
  height: 37px;

  margin-bottom: 25px;

  color: #213a2c;
  font-family: Permian;
  font-size: 14px;

  background-color: #d3cc9e;
  border: ${(props) =>
    !props.haserror ? "1px solid #205b41" : "1px solid #FF4040"};
  outline: none;

  padding-left: 5px;

  &:hover,
  &:focus,
  &:focus-visible {
    border: ${(props) =>
      !props.haserror ? "2px solid #205b41" : "2px solid #FF4040"};
  }

  &::placeholder {
    color: white;
  }
`;

const ContactTextArea = styled.textarea<{ haserror?: boolean }>`
  width: 100%;
  height: 83px;

  margin-bottom: 50px;

  color: #213a2c;
  font-family: Permian;
  font-size: 14px;

  background-color: #d3cc9e;
  border: ${(props) =>
    !props.haserror ? "1px solid #205b41" : "1px solid #FF4040"};
  outline: none;

  padding-left: 5px;

  &:hover,
  &:focus,
  &:focus-visible {
    border: ${(props) =>
      !props.haserror ? "2px solid #205b41" : "2px solid #FF4040"};
  }

  &::placeholder {
    color: white;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 25px;
  }
`;

const SubmitWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SubmitText = styled.p`
  font-family: Permian;
  color: #205b41;
  font-size: 0px;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const SubmitButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #205b41;

  &:hover {
    background-color: #6ea089;
  }

  border: 1px solid #205b41;

  font-family: Permian;
  color: white;
  font-size: 14px;

  cursor: pointer;

  width: 128px;
  height: 36px;
`;
