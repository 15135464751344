import React from "react";
import Terms from "./components/Terms.tsx";
import Welcome from "./components/Welcome.tsx";
import Footer from "./components/Footer.tsx";

function TermsAndConditions() {
  return (
    <React.Fragment>
      <Welcome />
      <Terms />
      <Footer />
    </React.Fragment>
  );
}

export default TermsAndConditions;
