import * as React from "react";
import { styled } from "styled-components";

const Terms = () => {
  return (
    <Wrapper>
      <Container>
        <Title>(NL) ALGEMENE VERKOOPSVOORWAARDEN Lab Agro GCV</Title>
        <br />
        <br />
        <Text>
          Tel : +32 474 577 122
          <br />
          <br />
          Email : friedvanopstal@terragusta.be
          <br />
          <br />
          Ondernemingsnummer : BE0672 402 218
          <br />
          <br />
          Bank: BE xx
          <br />
          <br />
          <br />
          Terragusta is een beschermde merknaam van Lab Agro GCV. Elke aankoop
          op de website www.terragusta.be of www.shopterragusta.be is onderhavig
          aan volgende algemene verkoopsvoorwaarden die u, als koper, verklaart
          te aanvaarden zonder enige reserve als u van de dienst van Lab Agro
          GCV gebruik maakt.
          <br />
          <br />
          <b>1. Bestelling</b>
          <br />
          U plaatst uw bestelling op de website www.terragusta.be of
          www.shopterragusta.be en volgt de aangeduide procedure. Het
          automatisch opslaan van de gegevens via de website www.terragusta.be
          of www.shopterragusta.be heeft als gevolg dat deze gegevens gelden als
          bewijs voor de aard, de inhoud en de datum van bestelling. Het online
          bevestigen van de bestelling geldt als elektronische ondertekening die
          tussen U en LAB AGRO dezelfde juridische waarde heeft als een
          geschreven handtekening. De verkoop wordt effectief vanaf het moment
          van bevestiging van de bestelling door LAB AGRO. Deze bevestiging
          houdt in: een overzicht van de bestellingen, het leveradres, het adres
          van LAB AGRO waarop u eventuele klachten kan formuleren; bij gebrek
          kan ook een rechtsgeldig betaalde factuur worden voorgelegd voor zover
          uw bank de betaling heeft aanvaard. LAB AGRO behoudt zich de
          mogelijkheid voor om bepaalde producten of bestellingen niet te
          bevestigen. Gezien de specificiteit van de producten die
          seizoensgebonden zijn, is het altijd mogelijk dat bepaalde producten
          niet of niet tijdig geleverd kunnen worden. LAB AGRO zal hierover de
          klant informeren op het moment van de levering en zal steeds een
          alternatief of een terugbetaling van het ontbrekende product
          voorstellen.
          <br />
          <br />
          <b>2. Prijs</b>
          <br />
          De prijzen staan aangeduid in euro, inclusief BTW voor consumenten en
          exclusief BTW in het geval van verkoop aan een zakelijke klant (staat
          duidelijk vermeld). Ze zijn slechts geldig op het moment van de
          bestelling. De prijzen kunnen op elk moment door LAB AGRO gewijzigd
          worden. Enkel de prijs op de website van LAB AGRO is geldig tussen
          partijen. U betaalt de prijs die geldt op de dag van de reservering. U
          kan slechts enkel op de dag van de reservering gebruik maken van de
          geldige waardebonnen, promoties of kortingen.
          <br />
          <br />
          <b>3. Betalingswijzen</b>
          <br />
          Om te betalen, beschikt u over verschillende betalingswijzen die
          aangeduid staan op het moment van de bestelling. De online betaling is
          mogelijk via volgende betaalmethodes: Bancontact en vindt plaats in
          een beveiligde omgeving (https protocol en cryptage sleutel). Voor
          zakelijke klanten is betaling per factuur eveneens mogelijk. De
          betalingswijzen kunnen door LAB AGRO op elk moment gewijzigd worden.
          De bestelling die door u bevestigd wordt zal slechts definitief
          doorgaan als de online betaling door het betalingsplatform definitief
          wordt goedgekeurd (voor niet factuur klanten). LAB AGRO behoudt zich
          het recht voor niet te leveren indien een bestelling of levering niet
          betaald is via het platform of via domiciliëring of in geval van
          geschillen tussen LAB AGRO en uzelf.
          <br />
          <br />
          <b>4. Kosten, timing en leveringsvoorwaarden</b>
          <br />
          Per bestelling kan een leveringskost worden aangerekend. Als deze
          verplicht is, wordt u hierover bij het begin van uw bestelling
          geïnformeerd. De leveringskosten kunnen door de koper niet worden
          betwist omdat hij ze bij de bevestiging van zijn bestelling ter kennis
          heeft genomen. De producten worden geleverd op het adres aangeduid
          door de koper op het moment van de bestelling. De koper dient de
          levering na te kijken en aan LAB AGRO eventuele problemen te
          signaleren, alsook de ontbrekende producten binnen de 24u na levering
          op volgend adres : friedvanopstal@terragusta.be LAB AGRO kan niet
          verantwoordelijk worden gesteld voor schade aan of kwaliteitsverlies
          van de producten indien de koper niet aanwezig is op het voorziene
          tijdstip van levering. De producten blijven beschikbaar gedurende 24u
          na het oorspronkelijke tijdstip van levering en kunnen door middel van
          een nieuwe afspraak en op kosten van de koper opnieuw worden
          aangeboden na afspraak via volgende mailadres:
          friedvanopstal@terragusta.be Na 24u worden de producten die nog
          bruikbaar zijn aan een goed doel geschonken. Indien er misbruik wordt
          gemaakt van de diensten van LAB AGRO of indien de koper meerdere keren
          afwezig is op het moment van levering, behouden wij ons het recht voor
          om de account van de koper te blokkeren en/of andere maatregelen te
          treffen om onze rechten te vrijwaren.
          <br />
          <br />
          <b>5. Herroepingsrecht</b>
          <br />
          Verse producten
          <br />
          LAB AGRO kan jammer genoeg geen herroepingsrecht toestaan voor de
          verse producten (zie uitzonderingen in art VI.53 van het Wetboek van
          Economisch Recht.). Producten met langere houdbaarheid Voor alle
          andere producten met een lange houdbaarheid is dit wel mogelijk. In
          dat geval heeft u het recht om binnen een termijn van 14 dagen zonder
          opgave van redenen de overeenkomst te herroepen. De herroepingstermijn
          verstrijkt 14 dagen na de levering. Om het herroepingsrecht uit te
          oefenen, moet u ons via een ondubbelzinnige verklaring (bv.
          schriftelijk per post of e-mail) op de hoogte stellen van uw
          beslissing de overeenkomst te herroepen. U kunt hiervoor gebruikmaken
          van het modelformulier voor herroeping
          (https://economie.fgov.be/sites/default/files/Files/Ventes/Forms/formulier-voor-herroeping.pdf),
          maar bent hiertoe niet verplicht. Om de herroepingstermijn na te leven
          volstaat het om uw mededeling betreffende uw uitoefening van het
          herroepingsrecht te verzenden voordat de herroepingstermijn is
          verstreken. Gevolgen van de herroeping Als u de overeenkomst herroept,
          ontvangt u alle betalingen die u tot op dat moment heeft gedaan,
          inclusief leveringskosten (met uitzondering van eventuele extra kosten
          ten gevolge van uw keuze voor een andere wijze van levering dan de
          door ons geboden goedkoopste standaard levering) onverwijld en in
          ieder geval niet later dan 14 dagen nadat wij op de hoogte zijn
          gesteld van uw beslissing de overeenkomst te herroepen, van ons terug.
          Wij betalen u terug via uw online saldo dat u altijd kan raadplegen en
          desgewenst een terugbetaling van kan vragen. In ieder geval zullen u
          voor zulke terugbetaling geen kosten in rekening worden gebracht.
          <br />
          <br />
          <b>6. Conformiteit</b>
          <br />
          U erkent alle informatie m.b.t. het product vóór zijn aankoop te
          hebben gelezen en erkent dat het product conform is aan uw bestelling,
          tenzij u eventuele problemen binnen de 24u heeft gemeld via
          friedvanopstal@terragusta.be . Zelfs al wordt de informatie zo
          zorgvuldig mogelijk door ons voorgesteld op de website, toch kunnen
          wij niet verantwoordelijk worden gesteld voor incomplete, foute of
          voorbijgestreefde informatie. De foto’s online illustreren de online
          catalogus en zijn niet contractueel. Sommige elementen op de foto zijn
          niet in de prijs inbegrepen. Op elk moment kunnen foto’s gewijzigd
          worden en LAB AGRO kan niet verantwoordelijk worden gesteld voor een
          niet conforme foto. LAB AGRO verschaft u de beste geschatte waarde van
          het gewicht van een vers product, maar gezien de aard van deze
          producten is het mogelijk dat het gewicht in de realiteit lichtjes
          positief of negatief verschilt.
          <br />
          <br />
          <b>7. Contractuele wijzigingen</b>
          <br />
          Wij behouden ons het recht voor deze verkoopsvoorwaarden op elk moment
          te wijzigen zonder voorafgaande kennisgeving. Elke bestelling die is
          uitgevoerd na de wijziging van deze voorwaarden veronderstelt de
          automatische aanvaarding van deze nieuwe voorwaarden.
          <br />
          <br />
          <b>8. Verantwoordelijkheid</b>
          <br />
          U heeft kennis genomen van het feit dat de verantwoordelijkheid van
          LAB AGRO te allen tijde beperkt is tot het bedrag van de bestelling,
          wat ook de aard van de schade moge zijn. In geval van niet uitvoering
          of gedeeltelijke uitvoering van de verkoop, zal LAB AGRO niet
          verantwoordelijk worden gesteld indien de gebrekkige uitvoering te
          wijten is aan de koper zelf of aan een onvoorspelbaar en onoplosbaar
          voorval dat te wijten is aan een derde of in geval van overmacht.
          Bovendien zal LAB AGRO niet verantwoordelijk kunnen worden gesteld
          voor elke schade die rechtstreeks of onrechtstreeks zou kunnen
          voortvloeien uit het gebruik van het verkochte product. LAB AGRO is
          niet verantwoordelijk voor eventuele ontoegankelijkheid van de website
          en de eventuele gevolgen hiervan. LAB AGRO kan nooit verantwoordelijk
          worden gesteld voor schade of andere incidenten die zouden kunnen
          voorkomen aan het informaticamateriaal of aan de gegevens die zijn
          opgeslagen op de computer of device van de gebruiker op het moment van
          de online toegang tot de website of tijdens het bezoek op één van de
          pagina’s van de site, alsook tijdens het downloaden van files en
          andere die van de website afkomstig zijn. In het bijzonder is LAB AGRO
          niet verantwoordelijk voor het doorgeven van informaticavirussen via
          zijn website. LAB AGRO is niet verantwoordelijk voor het frauduleuze
          gebruik van zijn omroepwijzen en kan in geen geval verantwoordelijk
          worden gesteld voor een eventuele intrusie in zijn IT-systemen of voor
          het stelen van gegevens via zijn systeem, gezien het feit dat LAB AGRO
          alles in zijn werk stelt om zich te behoeden voor dergelijke
          onrechtmatige intrusies. Uiteraard voldoet LAB AGRO aan de voorwaarden
          van GDPR. Voor meer informatie verwijzen we naar onze privacy policy.
          De webpagina’s die zich buiten de domeinnaam www.terragusta.be of
          www.shopterragusta.be bevinden en in het bijzonder de pagina’s
          waarnaar u kan doorverwezen worden via hyperlinks vallen volledig
          onder de verantwoordelijkheid van de eigenaars van desbetreffende
          webpagina's Wij engageren ons om uw gegevens enkel te gebruiken om u
          communicaties te versturen i.v.m. LAB AGRO en/of de correcte
          afhandeling van het contract te verzekeren. Indien u per e-mail wenst
          op de hoogte te worden gehouden, dient u ons uw mailadres te bezorgen.
          Uw gegevens komen terecht in onze klantenlijst en worden vertrouwelijk
          behandeld. U mag ook weigeren dat LAB AGRO u mails verstuurt. U hoeft
          gewoon de juiste vakjes aan- of af te vinken. Voor meer informatie
          verwijzen wij naar onze Privacy Policy.
          <br />
          <br />
          <b>9. Bewijslast</b>
          <br />
          De IT-journalen of « logs » die door onze IT-systemen worden verwerkt,
          worden als geldig bewijsmateriaal aanvaard tussen partijen (bewijs van
          bestellingen, elektronische communicatie, betalingen,…).
          <br />
          <br />
          <b>10. Ondeelbaarheid</b>
          <br />
          Indien één van de artikelen van deze verkoopsvoorwaarden om één of
          andere reden teniet wordt verklaard, verklaren partijen zich akkoord
          dat de andere artikelen geldig blijven. Het feit dat LAB AGRO
          eventueel zou afzien van haar recht om één van de artikelen volledig
          toe te passen, mag niet betekenen dat LAB AGRO stilzwijgend afstand
          doet van al haar rechten en zal LAB AGRO steeds de mogelijk bieden om
          later op deze voorwaarden terug te kunnen komen.
          <br />
          <br />
          <b>11. Klachten</b>
          <br />
          Klachten in verband met online aankopen dienen steeds binnen de 24u na
          levering van de bestelling te worden ingediend. Elke klacht kan per
          mail verstuurd worden naar friedvanopstal@terragusta.be en moet de
          nodige referenties over de betrokken artikelen bevatten, alsook de
          leverdatum en de exacte motieven van de klacht.
          <br />
          <br />
          <b>12. Toepasbaar recht en bevoegde rechtbank</b>
          <br />
          In geval van geschillen over toepassing van deze voorwaarden, geven
          LAB AGRO en de koper bevoegdheid aan de Rechtbanken van het
          Gerechtelijk Arrondissement Antwerpen. Het “ODR”-platform van de EU is
          een website bestemd voor consumenten en professionelen die online
          transacties verrichten in de EU (grensoverschrijdende of louter
          nationale transacties). Het is een hulpmiddel om geschillen over
          online aankopen op te lossen, zonder naar de rechter te stappen.
          Conform art. 14.1 van Verordening (EU) nr. 524/2013U kan u naar
          volgende link surfen: https://webgate.ec.europa.eu/odr
        </Text>
      </Container>
    </Wrapper>
  );
};

export default Terms;

const Wrapper = styled.div`
  width: 100%;
  left: 0;
  margin-left: 0;

  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  background-position: 50% 55%;
  background-size: cover;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url("assets/images/paper.jpg");

  padding-top: 75px;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;

  @media only screen and (max-width: 768px) {
    padding-top: 50px;

    padding-left: 10px;
    padding-right: 10px;
  }
`;

const Title = styled.b`
  font-size: 32px;
  font-family: Permian;
  color: #205b41;

  @media only screen and (max-width: 935px) {
    font-size: 26px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

const Text = styled.p`
  font-size: 16px;
  font-family: Permian;
  color: #205b41;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
