import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Welcome = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
  };

  return (
    <Wrapper>
      <LogoContainer>
        <Logo
          src="assets/images/Terragusta_Logo_baseline_2022_white yellow_RGB.svg"
          alt="logo"
          onClick={goToHome}
        />
      </LogoContainer>
    </Wrapper>
  );
};

export default Welcome;

const Wrapper = styled.div`
  width: 100%;
  left: 0;
  margin-left: 0;
`;

const LogoContainer = styled.div`
  height: 600px;
  width: 100%;
  background-position: 50% 55%;
  background-size: cover;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url("assets/images/header.jpg");

  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 935px) {
    height: 400px;
  }

  @media only screen and (max-width: 768px) {
    height: 250px;
  }
`;

const Logo = styled.img`
  height: 349px;
  width: 289px;
  cursor: pointer;

  @media only screen and (max-width: 935px) {
    height: 200px;
    width: 260px;
  }

  @media only screen and (max-width: 768px) {
    height: 150px;
    width: 200px;
  }
`;
