import Welcome from "./components/Welcome.tsx";
import Description from "./components/Description.tsx";
import Food from "./components/Food.tsx";
import Contact from "./components/Contact.tsx";
import Footer from "./components/Footer.tsx";
import React from "react";
import emailjs from "@emailjs/browser";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
//import Coming from "./components/Coming.tsx";

function Home() {
  React.useEffect(() => emailjs.init("WJFYjRbSQVUYw29Ml"), []);

  const options = {
    position: positions.TOP_CENTER,
    timeout: 5000,
    offset: "30px",
    transition: transitions.SCALE,
  };

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <Welcome />
      <Description />
      <Food />
      <Contact />
      <Footer />
    </AlertProvider>
    // <React.Fragment>
    //   <Welcome />
    //   <Coming />
    // </React.Fragment>
  );
}

export default Home;
