import * as React from "react";
import styled from "styled-components";

const Food = () => {
  return (
    <Wrapper>
      <Container>
        <TextContainer>
          <div>
            <FoodTitle>SMAAK & BELEVING</FoodTitle>
            <FoodText>
              Hennep is een wonderbaarlijke plant met tal van positieve
              eigenschappen. Wij telen hennep zonder hallucinogene stoffen voor
              de vezel en de zaden. Uit de zaden persen wij een lekkere, extra
              vierge, olie rijk aan omega vetzuren. Proef ze en laat je
              verrassen.
              <br />
              Nootachtig, grassmaak, zacht en soms met pikante nasmaak, … zijn
              de reacties als de olie geproefd wordt.
              <br />
              <br />
              Gebruik de olie niet om te bakken of braden. De goede
              eigenschappen van de olie gaan verloren. De olie is rijk aan
              onverzadigde vetzuren, perfect als een gezonde en lekkere
              aanvulling op uw koude en warme gerechten. Gebruik ze voor:
            </FoodText>
          </div>
          <Image src="assets/images/hand.jpg" />
        </TextContainer>
        <LogoWrapper>
          <LogoItem>
            <Logo src="assets/images/logos/bread.png" />
            <LogoText>brood of toast</LogoText>
          </LogoItem>
          <LogoItemRight>
            <LogoRight src="assets/images/logos/meat.png" />
            <LogoText>gebakken vlees</LogoText>
          </LogoItemRight>
        </LogoWrapper>
        <LogoWrapper>
          <LogoItem>
            <Logo src="assets/images/logos/sticks.png" />
            <LogoText>asperges en gegrilde groenten</LogoText>
          </LogoItem>
          <LogoItemRight>
            <LogoRight src="assets/images/logos/bowl.png" />
            <LogoText>marinades en vinaigrettes</LogoText>
          </LogoItemRight>
        </LogoWrapper>
        <LogoWrapper>
          <LogoItem>
            <Logo src="assets/images/logos/leaf.png" />
            <LogoText>slaatjes en groenten</LogoText>
          </LogoItem>
          <LogoItemRight>
            <LogoRight src="assets/images/logos/drop.png" />
            <LogoText>bron van omega vetzuren</LogoText>
          </LogoItemRight>
        </LogoWrapper>
        <LogoWrapper>
          <LogoItem>
            <Logo src="assets/images/logos/hand.png" />
            <LogoText>body-care, massage</LogoText>
          </LogoItem>
          <LogoItemRight>
            <LogoRight src="assets/images/logos/thc.png" />
            <LogoText>Bevat geen THC</LogoText>
          </LogoItemRight>
        </LogoWrapper>
      </Container>
    </Wrapper>
  );
};

export default Food;

const LogoWrapper = styled.div`
  width: 1100px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  @media only screen and (max-width: 935px) {
    width: 750px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
    margin-bottom: 0px;
  }

  @media only screen and (max-width: 1120px) {
    width: 900px;
  }

  @media only screen and (max-width: 920px) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const TextContainer = styled.div`
  width: 1100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 1120px) {
    width: 900px;
  }

  @media only screen and (max-width: 920px) {
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }
`;

const Image = styled.img`
  border: 7px solid #205b41;
  width: 400px;
  height: 400px;

  object-fit: cover;

  @media only screen and (max-width: 920px) {
    margin-bottom: 40px;
    height: auto;
  }

  @media only screen and (max-width: 420px) {
    width: 99%;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  left: 0;
  margin-left: 0;
`;

const Container = styled.div`
  width: 100%;
  background-position: 50% 55%;
  background-size: cover;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url("assets/images/paper.jpg");

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 75px;
  padding-bottom: 50px;

  @media only screen and (max-width: 768px) {
    padding-top: 50px;
  }
`;

const FoodTitle = styled.p`
  font-family: Operetta;
  font-size: 28px;

  color: #205b41;

  text-align: left;
  width: 500px;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  @media only screen and (max-width: 1120px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }
`;

const FoodText = styled.p`
  font-family: Permian;
  font-size: 20px;

  color: #205b41;

  margin-top: 20px;
  text-align: left;
  padding-bottom: 60px;
  width: 500px;

  @media only screen and (max-width: 768px) {
    font-size: 17px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 30px;
  }

  @media only screen and (max-width: 1120px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }
`;

const LogoItem = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const LogoItemRight = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const Logo = styled.img`
  width: 65px;

  margin-right: 15px;

  @media only screen and (max-width: 768px) {
    width: 50px;
  }
`;

const LogoText = styled.p`
  font-family: Permian;
  font-size: 20px;
  color: #205b41;

  margin-bottom: 0px;

  @media only screen and (max-width: 768px) {
    font-size: 17px;
  }
`;

const LogoRight = styled.img`
  width: 65px;

  margin-left: 15px;
`;
